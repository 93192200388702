import {computed, onMounted} from 'vue'

export const useBreakpoints = () => {
  const isDesktop = computed(() => process.client && window.matchMedia('(min-width: 1024px)').matches)
  const isTablet = computed(
    () =>
      process.client &&
      window.matchMedia('(min-width: 768px)').matches &&
      window.matchMedia('(max-width: 1023px)').matches,
  )
  const isMobile = computed(() => process.client && window.matchMedia('(max-width: 767px)').matches)

  const isDesktopOrTablet = computed(() => process.client && window.matchMedia('(min-width: 768px)').matches)
  const isTabletOrMobile = computed(() => process.client && window.matchMedia('(max-width: 1023px)').matches)

  onMounted(() => {
    isDesktop.value
    isTablet.value
    isMobile.value
    isDesktopOrTablet.value
    isTabletOrMobile.value
  })

  return {
    isDesktop,
    isTablet,
    isMobile,
    isDesktopOrTablet,
    isTabletOrMobile,
  }
}
